.cookie-alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 8px 16px;
  background: $dark-high;
  border-top: 1px solid #ebedf5;
  box-shadow: 0 3px 42px 0 rgb(38 39 59 / 7%);
  z-index: 99;
  h6 {
    margin-bottom: 12px;
    @include ultra-mobile {
      margin-bottom: 6px;
    }
  }
  p {
    margin-bottom: 8px;
    @include ultra-mobile {
      margin-bottom: 12px;
    }
    a {
      border-bottom: 1px solid $high;
      &:hover {
        border-color: transparent;
      }
    }
  }
  .action-wrap {
    @include flex-start-center;
    .outline-btn {
      margin-left: 16px;
    }
  }
}
