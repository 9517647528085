/*Footer Starts*/

.footer-main {
  padding-top: 32px;
  @include ultra-mobile {
    padding-top: 24px;
  }
  .divideby_img {
    margin: 8px 0 32px 0;
    @include ultra-mobile {
      margin: 8px 0 16px 0;
    }
  }

  .footer-top {
    padding: 32px 0;
    border-top: 1px solid $high;
    border-bottom: 1px solid $high;
    @include ultra-mobile {
      padding-bottom: 40px;
    }
    .footer-top-wrap {
      @include flex-between-start;
      @include ultra-mobile {
        @include prefix(flex-direction, column);
      }
      p[class*="title"] {
        margin-bottom: 20px;
        @include ultra-mobile {
          margin-bottom: 12px;
        }
      }
      .help-section {
        @include ultra-mobile {
          width: 100%;
        }
        .desc-text {
          margin-bottom: 20px;
          @include ultra-mobile {
            margin-bottom: 0;
          }
        }
        .additional-links {
          max-width: 600px;
          @include flex-between-center;
          @include prefix(flex-wrap, wrap);
          @include ultra-mobile {
            @include prefix(flex-direction, column);
            @include prefix(align-items, start);
          }
          @include custom-tablet {
            @include flex-start-center;
            .point-email {
              flex-basis: 100%;
              margin-top: 12px;
            }
          }
          [class*="point-"] {
            @include flex-start-center;
            @include ultra-mobile {
              padding-top: 16px;
            }
            &:before {
              content: " ";
              @include hw(24px);
              display: inline-block;
              margin-right: 8px;
              background-repeat: no-repeat;
            }
          }
          .point-tel {
            &:before {
              background: url("../../Assets/Images/phone.svg");
            }
          }
          .point-schedule {
            &:before {
              background: url("../../Assets/Images/clock-black.svg");
            }
          }
          .point-email {
            &:before {
              background: url("../../Assets/Images/mail.svg");
            }
          }
          .point-addr {
            flex-basis: 100%;
            padding-top: 20px;
            &:before {
              background: url("../../Assets/Images/location.svg");
            }
          }
        }
      }
      .newsletter-section {
        width: calc(100% - 784px);
        @include desktop {
          width: calc(100% - 640px);
        }
        @include tablet {
          width: calc(100% - 400px);
          padding-left: 40px;
        }
        @include ultra-mobile {
          width: 100%;
          padding-left: 0;
          padding-top: 40px;
        }
        .input-action {
          position: relative;
          max-width: 352px;
          margin-top: 19px;
          @include ultra-mobile {
            max-width: 100%;
            margin-top: 16px;
          }
          .input-groups {
            margin-bottom: 0;
            label {
              display: none;
            }
          }
          .footer-newsletter {
            margin-top: 16px;
            margin-bottom: 0;
          }
          .submit-action {
            background-color: $primary;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border: 0;
            padding: 0 12px;
            height: 48px;
            @include prefix (border-top-right-radius, 4px);
            @include prefix (border-bottom-right-radius, 4px);
            position: absolute;
            top: 0;
            right: 0;
            @include prefix(transition, all 300ms ease-in);
            &:hover {
              background-color: $button-hover;
              @include prefix(transition, all 300ms ease-in);
            }
          }
        }
      }
    }
  }
  .footer-secondary {
    border-bottom: 1px solid $line;
    @include ultra-mobile {
      border-bottom: 0;
      .custom-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .footer-menu {
      padding-top: 32px;
      padding-bottom: 64px;
      @include ultra-mobile {
        padding-top: 8px;
        padding-bottom: 0px;
      }
      .accordion {
        @include flex-between-start;
        @include ultra-mobile {
          @include prefix(flex-direction, column);
        }
        .accordion-item {
          border: 0;
          @include ultra-mobile {
            width: 100%;
            padding: 24px 12px;
            border-bottom: 1px solid $line;
          }
          @include ultra-mobile-above {
            width: calc(100% / 6);
          }
          .accordion-button {
            padding: 0;
            background-color: $white;
            border: 0;
            @include prefix(box-shadow, none);
            @include fonts($InterFont, $high, 14px, 600, 20px, 1.6px);
            text-transform: uppercase;
            @include ultra-mobile-above {
              pointer-events: none;
              &:not(.collapsed) {
                background-color: $white;
              }
              &:after {
                display: none !important;
              }
            }
            &.collapsed {
              &:after {
                content: " ";
                @include hw(24px);
                display: inline-block;
                background-repeat: no-repeat;
                background-image: url("../../Assets/Images/plus.svg");
                background-size: auto;
                @include prefix(transform, rotate(0));
              }
            }
            &:after {
              content: " ";
              @include hw(24px);
              display: inline-block;
              background-repeat: no-repeat;
              background-image: url("../../Assets/Images/minus.svg");
              background-size: auto;
              @include prefix(transform, rotate(0));
            }
          }
          .collapse {
            &:not(.show) {
              @include ultra-mobile-above {
                display: block;
              }
            }
          }
          .accordion-body {
            padding: 0;
            padding-top: 20px;
            li {
              &:not(:last-child) {
                margin-bottom: 12px;
                @include ultra-mobile {
                  margin-bottom: 20px;
                }
              }
              &:last-child {
                @include ultra-mobile {
                  margin-bottom: 8px;
                }
              }
              a {
                @include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
                &:hover {
                  color: $high;
                }
                @include ultra-mobile {
                  color: $high;
                }
              }
            }
          }
        }
      }
    }
    .lower-line {
      text-align: center;
      padding: 0 0 12px 0;
      color: $mid;
      @include ultra-mobile {
        padding: 0 12px 20px 12px;
      }
    }
  }
  .footer-bottom {
    border-bottom: 1px solid $line;
    .footer-bottom-wrap {
      @include flex-between-center;
      padding: 16px 0;
      @include ultra-mobile {
        @include prefix(flex-direction, column);
        padding: 16px 0 12px 0;
        .image-points {
          order: 1;
        }
        .copyright {
          order: 2;
          padding-top: 20px;
        }
        .madeby {
          order: 3;
          padding-top: 8px;
        }
      }
      p {
        color: $mid;
        @include ultra-mobile {
          color: $low;
        }
      }
      .madeby {
        .company-link {
          color: $mid;
          padding-left: 4px;
          &:hover {
            color: $high;
          }
          @include ultra-mobile {
            color: $low;
          }
        }
      }
    }
  }
  .footer-divideby-wrap {
    text-align: center;
    padding: 30px 0px;
    p {
      color: $mid;
      @include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
      @include ultra-mobile {
        color: $low;
      }
    }
  }
}

/*Footer Ends*/
