.dividebuy-text-left {
    text-align: left !important;
}

.custom_divideby {
    .inner_divide {
        @include flex-start-center;
        margin-bottom: 8px;
        & > * {
            margin-right: 4px;
            &:last-child {
                margin-right: 0;
            }
        }
        .dividebuy-left-text {
            // @include flex-start-center;
            // align-items: self-end;
            img {
                width: 64px;
                height: 16px;
                // margin-top: 4px;
            }
            & > * {
                margin-right: 6px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @include mini-mobile {
                span {
                    @include fonts($InterFont, $high, 12px, 400, 16px, 0.4px);
                }
            }
        }
        // .popup_action {
        //     img {
        //         cursor: pointer;
        //     }
        // }
    }
    .dividebuy-softsearch {
        a {
            & > span {
                @include flex-start-center;
                span {
                    font-family: 'Poppins', sans-serif;
                    border-bottom: 1px solid transparent;
                    margin-left: 8px;
                    &:hover {
                        border-color: $high;
                    }
                }
            }
        }
    }
    .dividebuy-fastcheck-text {
        margin-top: 2px;
        @include fonts($InterFont, $mid, 12px, 400, 16px, 0.2px);
    }
}
