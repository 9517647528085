.eligibility_popup {
    .modal-header {
      padding-bottom: 0;
    }
    .dividebuy-loader{
      background: rgba(0, 0, 0, 0) url(../../../Assets/Images/dividebuy-loader.svg) no-repeat scroll 0 0 / 100% auto;
      height: 85px;
      left: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      width: 85px;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
    .modal-dialog {
      max-width: 650px;
      @include mini-mobile {
        .modal-body {
          padding: 4px;
        }
      }
    }
    .modal-header {
      justify-content: end;
    }
  }
  