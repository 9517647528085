.inner-minicart {
  @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
  @include prefix(display, flex);
  @include prefix(flex-direction, column);
  max-height: 400px;
  .minicart-top {
    @include flex-between-center;
    @include prefix(flex-wrap, wrap);
    padding: 16px;
    .action-bar {
      padding: 16px 0 0 0;
      flex-basis: 100%;
      .fill-btn {
        width: 100%;
      }
    }
  }
  .minicart-center {
    flex: 1;
    @include flex-start-start;
    overflow-y: auto;
    padding: 0 0 0 16px;
    scrollbar-color: $low #e7e7e7;
    scrollbar-width: thin;
    /* width */
    &::-webkit-scrollbar {
      width: 16px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border: 1px solid #e7e7e7;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: $low;
      @include prefix(border-radius, 8px);
      border: 4px solid transparent;
      background-clip: content-box;
    }
    .product-list {
      flex-basis: 100%;
      .item {
        @include flex-between-start;
        padding-bottom: 16px;
        padding-right: 16px;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 16px;
          border-bottom: 1px solid $line;
        }
        .left-side {
          img {
            width: 88px;
            max-width: 88px;
            height: 64px;
            @include mobile {
              width: 64px;
              max-width: 64px;
              height: 48px;
            }
          }
        }
        .right-side {
          width: calc(100% - 96px);
          @include mobile {
            width: calc(100% - 72px);
          }
        }
        .attributes {
          padding-top: 7px;
          @include prefix(display, flex);
          @include prefix(flex-wrap, wrap);
          p {
            color: $mid;
            position: relative;
            margin-bottom: 8px;
            span {
              text-transform: initial;
            }
            &:not(:first-child) {
              padding-left: 12px;
              &::before {
                content: ' ';
                background-color: $line;
                @include hw(4px);
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 4px;
              }
            }
          }
        }
        .inc-dec-box {
          padding-top: 10px;
          text-transform: uppercase;
          @include flex-between-center;
          p {
            color: $mid;
            padding-right: 8px;
          }
          .inleft-side {
            @include flex-start-center;
          }
          .inright-side {
            @include flex-end-center;
            img{
              width: 16px;
              height: 16px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .minicart-bottom {
    padding: 16px;
    .action-bar {
      .outline-btn {
        width: 100%;
      }
    }
  }
  .empty-cart{
    text-align: center;
    padding: 25px;
  }
}

/*mini cart delete item start*/
.remove-popup{
  .remove-wrap{
    padding: 30px 0 0;
    text-align: right;
    button{
      &.fill-btn{
        margin-left: 24px;
      }
     

    }
  }
}
/*mini cart delete item end*/
