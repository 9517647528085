.error-page {
	padding: 96px 0px 64px 0px;
	@include ultra-mobile {
		padding: 24px 0px 56px;
	}
	.custom-container {
		max-width: 1030px;
		margin: 0 auto;
	}
	.error-page-inner {
		@include flex-between-center;
		@include ultra-mobile {
			flex-wrap: wrap;
		}
		.fill-btn {
			margin-top: 23px;
      width: 100%;
      max-width: 200px;
      @include ultra-mobile {
        max-width: 100%;
      }
		}
		.error-box {
			&.left {
				max-width: 352px;
        width: 100%;
				@include ultra-mobile {
					max-width: 100%;
					padding-top: 0px;
				}
				h6 {
					margin-bottom: 9px;
          @include ultra-mobile {
            text-align: center;
          }
				}
        p {
          @include ultra-mobile {
            text-align: center;
          }
        }
			}
			&.right {
				.error-img {
					@include ultra-mobile {
						width: 100%;
            padding: 0 32px;
					}
				}
				@include ultra-mobile {
					order: -1;
					img {
						margin: 0 0 32px 0;
					}
				}
			}
		}
	}
}
