.catalog-search-page {
  .section-title {
    margin-top: 24px;
    word-break: break-word;
  }
  .custom-container {
    .product-list {
      display: block;
      .product-list {
        @include flex-start-center;
        flex-wrap: wrap;
        gap: 0 24px;
      }
    }
  }
}