/*Top Bar Starts*/

.header-panel {
  // background-color: $secondary;
  background-color: #be3e3f;
  padding: 8px 0;
  @include tablet {
    min-height: 32px;
  }
  @include desktop-above {
    max-height: 40px;
    padding: 9px 0;
  }
  .panel-info {
    @include flex-between-center;
    @include tablet {
      @include flex-center-center;
    }
    .free-sample {
      cursor: pointer;
    }
    > * {
      flex-basis: calc(100% / 3);
    }
    span,
    a {
      color: $white;
      @include ultra-mobile {
        @include fonts($InterFont, $white, 12px, 500, 16px, 0.5px);
      }
    }
    @include mini-iphone-mobile {
      text-align: center;
    }
    .center-line {
      text-align: center;
      @include tablet {
        flex-basis: 100%;
      }
    }
    .authentication-wrap {
      text-align: right;
    }
    .authentication-wrap {
      .login,
      .c-acc {
        border-right: 1px solid $line;
        padding-right: 16px;
        margin-right: 16px;
      }
    }
    @include tablet {
      span.free-sample,
      .authentication-wrap {
        display: none;
      }
    }
    @media (min-width: 768px) and (max-width: 992px) {
      .lm {
        @include fonts($InterFont, $white, 11px, 500, 16px, 0.5px);
      }
      .ll {
        @include fonts($InterFont, $white, 11px, 500, 16px, 0.5px);
      }
      .authentication-wrap {
        @include prefix(justify-content, flex-end);
        @include prefix(align-items, center);
        .login,
        .c-acc {
          border-right: 1px solid $line;
          padding-right: 8px;
          margin-right: 8px;
        }
      }
    }
  }
}

/*Top Bar Ends*/

/*Header Starts*/

.header-main {
  position: relative;
  @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
}

.header-content {
  // position: sticky;
  // top: 0;
  // z-index: 999;
  background-color: $white;
  position: relative;
  .header-web {
    padding: 12px 0;
    .header-content {
      @include flex-between-center;
      .logo {
        a {
          @include tablet-above {
            display: block;
          }
        }
        img {
          width: 248px;
        }
        .static-height {
          height: 24px;
        }
      }
      .left-info {
        span,
        a {
          color: $mid;
          @include prefix(display, flex);
          @include prefix(flex-direction, column);
        }
      }
      .right-info {
        padding-right: 4px;
        @include prefix(display, flex);
        @include prefix(align-items, center);
        .right-link {
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 24px;
          }
        }
        .minicart {
          position: relative;
          @include flex-between-center;
          img {
            max-width: 24px;
            margin-right: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.mobile-header-wrap {
  // position: sticky;
  // top: 0;
  // z-index: 999;
  background-color: $white;
  position: relative;
  padding: 12px 0;
  .header-mob {
    @include flex-between-center;
    position: relative;
    .mobile-center {
      @include prefix(display, flex);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      img {
        max-width: 124px;
      }
    }
    .mobile-left {
      @include prefix(display, flex);
      position: absolute;
      left: -12px;
      z-index: 99;
      img {
        padding: 8px;
      }
    }
    .mobile-right {
      margin-left: auto;
      @include prefix(display, flex);
      a {
        @include prefix(display, flex);
        @include prefix(align-items, center);
        &:not(:last-child) {
          margin-right: 16px;
        }
        @include ultra-mobile {
          span {
            margin-left: 4px;
          }
        }
      }
      .minicart {
        position: relative;
        @include prefix(display, flex);
        a {
          margin-right: 0;
        }
       .minicart-wrapper.open{
          padding: 0;
          display: inline-block;
          width: 304px;
          position: absolute;
          top: 36px !important;
          background-color: $white;
          right: 0 !important;
          z-index: 2;
          @include ultra-mobile {
            z-index: 90;
          }
          transform: none !important;
          border: 0 !important;
        }
      }
    }
  }
}

/*Header Ends*/

/*Header Search Functionality Starts*/

.search-box {
  background-color: $white;
  padding: 20px 0;
  display: block;
  @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
  border-top: 1px solid $line;
  &.close {
    display: none;
  }
  &.open {
    position: absolute;
    top: 141px;
    z-index: 3;
    width: 100%;
    @include desktop {
      top: 140px;
    }
    @include desktop-above {
      top: 144px;
    }
    @include tablet {
      top: 134px;
    }
    @include ultra-mobile {
      top: 80px;
      padding: 12px 0;
    }
  }
  .search-grp {
    position: relative;
    input[type="search"] {
      outline-offset: 0px;
      width: 100%;
      border: 0;
      @include prefix(box-shadow, none);
      padding: 0 0 0 32px;
      background-image: url("../../Assets/Images/search.svg");
      background-position: left center;
      background-repeat: no-repeat;
      @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
      background-color: transparent;
    }
    .close-search {
      border: 0;
      @include prefix(box-shadow, none);
      padding: 0;
      @include flex-center-center;
      position: absolute;
      top: 0;
      right: 0;
      &::before {
        content: " ";
        background-image: url("../../Assets/Images/close.svg");
        @include hw(24px);
        display: inline-block;
        background-color: $white;
      }
    }
  }
}

/*Header Search Functionality Ends*/

/*Header Minicart Functionality Starts*/

.minicart-wrapper {
  &.close {
    display: none;
  }
  &.open {
    // display: inline-block;
    // width: 384px;
    // position: absolute;
    // top: 36px;
    // background-color: $white;
    // right: 0;
    // z-index: 2;
    // @include iphone {
    //   width: calc(304px - 20%);
    // }
  }
}

.minicart {
  .dropdown-toggle {
    position: relative;
    background-color: transparent!important;
    border: 0!important;
    box-shadow: none!important;
    outline: none!important;
    padding: 0;
    &:after {
      display: none;
    }
    span {
      position: absolute;
      right: -6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .dropdown-menu.show {
    padding: 0;
    display: inline-block;
    width: 384px;
    position: absolute;
    top: 36px !important;
    background-color: $white;
    right: 0 !important;
    z-index: 2;
    transform: none !important;
    border: 0 !important;
    @include iphone {
      width: calc(304px - 20%);
    }
  }
}

/*Header Minicart Functionality Ends*/

/*MiniCart Open BG Overlay Starts*/
.dropdown-back {
  background-color: transparent;
  bottom: 0;
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  @include ultra-mobile {
    z-index: 88;
  }
  &.show {
    display: block;
  }
}
/*MiniCart Open BG Overlay Ends*/