.categories {
  .custom-container {
    .nodata-found {
      margin: 12px 0;
    }
    .cus-breadcrumb {
      display: none;
      margin: 12px 0;
      text-transform: capitalize;
    }
    .cat-banner {
      margin-top: 24px;
      @include flex-start-center;
      background: $secondary;
      @include tablet {
        flex-wrap: wrap;
        background: transparent;
      }
      .cat-txt {
        width: 32%;
        max-width: 360px;
        margin: 0 auto;
        @include desktop {
          max-width: 40%;
          padding: 0 12px;
        }
        @include tablet {
          max-width: 100%;
        }
        @include tablet {
          height: auto;
          text-align: center;
          background-color: $white;
          width: 100%;
          padding: 16px 0;
          @include prefix(box-shadow, 0px 9px 22px rgb(40 44 63 / 7%));
        }
        h1 {
          @include fonts($MarcellusFont, $high, 24px, 400, 32px, 0.03em);
          color: $white;
          padding-bottom: 16px;
          @include desktop {
            padding-bottom: 12px;
          }
          @include tablet {
            color: $high;
            padding-bottom: 0;
          }
        }
        p {
          color: $white;
          margin-bottom: 35px;
          @include media-1299-below {
            margin-bottom: 16px;
          }
          @include desktop {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          @include tablet {
            display: none;
          }
        }
        .cat-service {
          @include flex-start-center;
          margin-bottom: 16px;
          @include desktop {
            margin-bottom: 12px;
          }
          @include tablet {
            display: none;
          }
          .cat-service-img {
            margin-right: 16px;
            img {
              height: 48px;
              width: 48px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          .cat-service-txt {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      .cat-banner-img {
        width: 68%;
        @include tablet {
          order: -1;
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
    .d-none {
      display: none;
    }
    .product-list {
      @include flex-start-center;
      flex-wrap: wrap;
      gap: 0 24px;
      width: 100%;
      > * {
        width: 100%;
      }
      &.loading {
        display: block;
      }
      .product-list {
        margin-top: 24px;
      }
      .product-list-item {
        flex-basis: calc(calc(100% / 3) - 16px);
        margin-bottom: 27px;
        @include ultra-mobile {
          flex-basis: calc(100%);
          margin-bottom: 24px;
        }
        @include tablet-above {
          max-width: 424px;
        }
        .product-list-item-txt {
          span {
            padding: 9px 0 4px;
            display: block;
            @include ultra-mobile-above {
              display: -webkit-box;
              max-width: 100%;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .price-wrapper {
          padding-top: 0;
        }
        .product-swatches{
          margin-top: 6px;
          .sample-main {
            margin: 3px 0 0;
  
            .sample-checkbox {
              @include flex-start-center;
              @include prefix(flex-wrap, wrap);
              gap: 4px 4px;
  
              @include ultra-mobile {
                @include prefix(flex-wrap, nowrap);
                gap: 8px 8px;
              }
            }
  
            .samp-tick {
              @include hw(28px);
              position: relative;
              cursor: pointer;
  
              @include ultra-mobile {
                &:last-child {
                  display: flex;
                  &:after {
                    content: "\00a0\00a0\00a0\00a0";
                    display: block;
                    width: 100%;
                    height: 4px;
                  }
                }
              }
  
              label {
                img {
                  width: 28px;
                  height: 28px;
                  @include prefix(border-radius, 4px);
                  border: 1px solid $line;
                  cursor: pointer;
  
                  &:hover {
                    border: 1px solid $high;
                  }
  
                 
                }
  
                span {
                  width: 28px;
                  height: 28px;
                  @include prefix(border-radius, 4px);
                  border: 1px solid $line;
                  display: inline-block;
                  cursor: pointer;
  
                  &:hover {
                    border: 1px solid $primary;
                  }
  
                  @include ultra-mobile {
                    @include hw(56px);
                  }
                }
              }
  
              input {
                width: 0;
                height: 0;
                border: 0;
              }
  
              &.selected {
                &::before {
                  content: "";
                  text-align: center;
                  position: absolute;
                  height: 16px;
                  width: 16px;
                  background: url("../../Assets/Images/check.svg") no-repeat center;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
  
                  @include tablet {
                    // height: 56px;
                    // width: 56px;
                  }
                }
  
                img {
                  border: 1px solid $high;
                }
              }
            }
  
            .sample-checkbox-mob {
              @include prefix(display, flex);
              @include prefix(flex-flow, column wrap);
              gap: 8px;
              align-content: flex-start;
              max-height: 184px;
  
              .no-combi-available {
                margin: 12px auto;
                padding-right: 12px;
                text-align: center;
              }
            }
          }
        }
        
        .image-top {
          display: block;
          overflow: hidden;
          position: relative;
          height: 0;
          padding-bottom: 75%;

          .product-list-badge-txt {
            position: absolute;
            z-index: 2;
            padding-right: 10px;
            padding-left: 14px;
            background-color: $secondary;
            top: 14px;
            padding-top: 2px;
            padding-bottom: 2px;
            left: 0;
            p {
              @include fonts($InterFont, $white, 14px, 400, 20px, 0.25px);
              position: relative;
              &::after {
                content: '';
                position: absolute;
                top: -2px;
                right: -24px;
                border-top: 24px solid transparent;
                border-left: 14px solid $secondary;
                width: 0;
              }
            }
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 100%;
            margin: auto;
            height: 100%;
            object-fit: cover;
            width: auto;
          }
        }
      }
      .loading-text {
        width: 100%;
        text-align: center;
        margin: 8px 0;
        @include flex-center-center;
        @include fonts($MarcellusFont, $high, 36px, 400, 44px, 0.02em);
        .loader-black {
          margin-left: 24px;
          display: inline-block;
          left: 0;
        }
      }
    }
  }
    // Seo code Faq Section 
.faq-wrap{
  &.faq-container{
    .custom-container{
      margin: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 32px;
      .faq-list{
        padding-top: 20px;
      }
    }
    
  }
}
}

/*Product Listing Skeleton Starts*/
.categories.skl {
  padding-top: 20px;
  width: 100%;
  .bread-c {
    max-width: 25%;
  }
  .product-list-item.skl {
    .img-skl {
      min-height: 280px;
      height: 100%;
      display: block;
      @include desktop {
        min-height: 200px;
      }
      @include tablet {
        min-height: 160px;
      }
      @include ultra-mobile {
        min-height: 200px;
      }
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .name-skl {
      max-width: 50%;
      margin-top: 4px;
    }
    .price-skl {
      max-width: 35%;
      margin-top: 4px;
    }
    .swatch-skl-group{
      display: flex;
      .swatch{
        width: 28px;
        height: 28px;
        border-radius: 1px;
        margin-right: 5px;
      }
      
    }
  }
}

.cat-banner.skl {
  padding-top: 20px;
  background-color: transparent !important;
  min-height: 400px;
  height: 100%;
  display: block;
  span {
    min-height: inherit;
    height: 100%;
    width: 100%;
  }
  @include ultra-mobile {
    margin: 0;
    min-height: 220px;
  }
}
/*Product Listing Skeleton Ends*/

/*Read More/Less Starts*/
.additional-desc {
  padding-top: 24px;
  h4 {
    margin-bottom: 8px;
  }
  h1 {
    margin-bottom: 8px;
    @include fonts($MarcellusFont, $high, 32px, 400, 40px, 0.03em);
  }
  p {
    @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
    p {
      @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
    }
  }
  .sub-p {
    display: inline;
    @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
  }
  .rdrl-btn {
    display: inline;
    background-color: transparent;
    padding: 0;
  
    border: 0;
    @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
    border-bottom: 1px solid $high;
    &:hover {
      border-color: transparent;
    }
    &.off {
      display: none;
    }
  }
  .more-desc {
    h6 {
      text-transform: capitalize;
      margin-bottom: 16px;
      &:first-child {
        margin-top: 12px;
      }
    }
    h3 {
      @include fonts($MarcellusFont, $high, 24px, 400, 32px, 0.03em);
      text-transform: capitalize;
      margin-bottom: 16px;
      &:first-child {
        margin-top: 12px;
      }
    }
    p {
      margin-bottom: 12px;
      @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
      &:last-child {
        margin-bottom: 0;
      }
    }

    .more-parag {
      margin-bottom: 12px;
    }
    .rdrl-btn {
      display: inline;
      background-color: transparent;
      padding: 0;
      margin-left: 0;
      border: 0;
      @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
      border-bottom: 1px solid $high;
      &:hover {
        border-color: transparent;
      }
      &.off {
        display: none;
      }
    }
  }
}
/*Read More/Less Ends*/

.categoeyimg-list {
  margin-top: 24px;
  .row {
    margin-left: -12px;
    margin-right: -12px;
    .item {
      padding: 0 12px;
      margin-bottom: 24px;
      a {
        display: block;
      }
      .cat-name {
        text-align: left;
        margin-top: 8px;
        display: block;
      }
    }
  }
}

.product-list {
  .price-wrapper {
    align-items: center;
    .vat-text {
      color: $mid;
      margin-left: 8px;
    }
  }
}

.product-txt-box {
  .product-price {
    .price {
      align-items: center;
      .vat-text {
        color: $mid;
        margin-left: 8px;
      }
    }
  }
}
