.install_popup {
    .modal-body {
        .content_wrap {
            .dividebuy-instalment {
                @include flex-between-start;
                margin-bottom: 12px;
                .dividebuy-monthly-instalments, .dividebuy-installment-amount {
                    @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
                    @include mini-mobile {
                        @include fonts($InterFont, $high, 12px, 400, 16px, 0.4px);
                    }
                }
                &.dividebuy-instalment-disabled {
                    pointer-events: none;
                    flex-wrap: wrap;
                    .dividebuy-spend-text {
                        @include fonts($InterFont, $mid, 12px, 400, 16px, 0.4px);
                        flex-basis: 100%;
                    }
                    .dividebuy-installment-amount {
                        @include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
                        @include mini-mobile {
                            @include fonts($InterFont, $mid, 12px, 400, 16px, 0.4px);
                        }
                        
                    }
                    .dividebuy-monthly-instalments {
                        @include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
                        @include mini-mobile {
                            @include fonts($InterFont, $mid, 12px, 400, 16px, 0.4px);
                        }
                        
                    }
                }
            }
            .dividebuy-instalment-total {
                margin-top: 12px;
                padding-top: 12px;
                border-top: 1px solid $line;
                margin-bottom: 16px;
                padding-bottom: 12px;
                border-bottom: 1px solid $line;
                @include flex-between-start;
                .dividebuy-instalment-total-text, .dividebuy-instalment-total-amount {
                    @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
                    @include mini-mobile {
                        @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
                    }
                }
            }
        }
        .dividebuy-interestfree-logo {
            text-align: center;
            & > img { width: auto; margin: auto; }
            .dividebuy-representative-text {
                p {
                    @include fonts($InterFont, $mid, 12px, 400, 16px, 0.4px);
                }
            }
        }
    }
}
