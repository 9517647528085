.faq-wrap {
  &.faq-container {
    text-align: left;
    .faq-header{
      @include fonts($MarcellusFont, $high, 32px, 400, 40px, 0.03em);
    }
  .faq-list {
    .accordion{
      .accordion-item{
        .accordion-header{
          padding: 16px 0px 16px 0px ;
          .accordion-button{
            padding: 0 !important;
            &.collapsed{

            }
          }
        }
        .accordion-body{
          padding: 0 0 20px 32px;
          .bs{
            color: $mid;
          }
        }
      }
    }
    .accordion-button{
        .bm {
            padding-left: 32px;
            position: relative;
            &:before {
              content: " ";
              background-image: url("../../Assets/Images/menu-up.svg");
              @include hw(24px);
              background-repeat: no-repeat;
              position: absolute;
              rotate: 180deg;
              top: 0;
              left: 0;
              background-position: center;
              transition: all 300ms ease-in;
            }
          }
          &.collapsed{
            .bm {
                &::before{
                    rotate: 0deg;
                    transition: all 300ms ease-in;
                }
            }
          }
    }
  }
}
}

