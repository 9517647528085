.authentication-wrap {
  .inner-main {
    margin: 0 auto;
    width: 100%;
    max-width: 352px;
    text-align: left;
    padding: 64px 0 27px;
    @include mobile {
      padding: 32px 0 16px;
      max-width: 100%;
    }
    .top-head {
      text-align: center;
      h1 {
        margin-bottom: 8px;
        @include fonts($MarcellusFont, $high, 24px, 400, 32px, 0.03em);
      }
      p {
        margin-bottom: 20px;
      }
      &.resetpwdtx{
        margin-bottom: 24px;
      }
    }
    .input-groups {
      margin-bottom: 24px;
    }
    .signup-news-ltr {
      margin-bottom: 24px;
    }
    .create-acc {
      margin-bottom: 28px;
      a {
        display: inline;
        border-bottom: 1px solid $high;
        &:hover {
          border-color: transparent;
        }
      }
    }
    .forgotpass {
      text-align: right;
      margin-bottom: 27px;
      a {
        border-bottom: 1px solid $high;
        &:hover {
          border-color: transparent;
        }
      }
    }
    .fill-btn {
      width: 100%;
      margin-bottom: 16px;
    }
    .form-bottom {
      text-align: center;
      a {
        border-bottom: 1px solid $high;
        &:hover {
          border-color: transparent;
        }
      }
    }
  }
  &.login {
    .inner-main {
      .input-groups {
        &.password {
          margin-bottom: 8px;
        }
      }
    }
  }
  &.forgotpwd {
    .captcha-section {
      margin: 24px 0;
      div {
        @include flex-between-center;
        canvas {
          @include mini-mobile {
            width: 40%;
          }
        }
        div {
          display: block;
        }
        #reload_href {
          @include flex-center-center;
          height: 48px;
          @include prefix(border-radius, 4px);
          outline: none;
          border: 1px solid $line;
          padding: 0 16px;
          text-align: center;
          box-sizing: border-box;
          @include prefix(box-shadow, none);
          text-decoration: none;
          @include prefix(transition, all 300ms ease-in);
          background-color: $white;
          @include fonts($InterFont, $high !important, 14px, 600, 20px, 1.6px);
          text-transform: uppercase;
          @include mini-mobile {
            padding: 0 8px;
          }
          &:hover {
            @include prefix(transition, all 300ms ease-in);
            cursor: pointer;
            border-color: $mid;
          }
          &[disabled] {
            cursor: not-allowed;
            opacity: 0.7;
            &:hover {
              border-color: $line;
            }
          }
        }
      }
    }
  }
}
